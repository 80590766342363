import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//evaluate imports
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider
} from "@apollo/client";

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache()
});

ReactDOM.render((
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
), document.getElementById('root'));