import React, {useContext, useState, useRef} from "react";
import { useHistory } from "react-router";
import {Button, Accordion, Card} from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import FormInput from "./FormInput";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
//import images
import collapse from '../images/versions/collapse.png';
import expand from '../images/versions/expand.png';
import upArrow from "../images/viewcomponent/moveup.png";
import downArrow from "../images/viewcomponent/movedown.png";
//import style
import '../styles/custom-form.css';
//import custom button
import CustomButton from './CustomButton';


function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
        type="button"
        style={{backgroundImage: isCurrentEventKey ? `url("${collapse}"` : `url("${expand}")`,backgroundSize: '100% 100%',border:"0",width:"60px"}}
        onClick={decoratedOnClick}
        >
        {children}
        </button>
    );
}

const AppComponentCard = React.forwardRef((props,ref) =>
//function AppComponentCard(props)
{
    async function updateComponent()
    {
        let updateResults = await fetch(`/component/${props.objectID}`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...fieldValues,version:props.versionID})
        });
        //get json from results
        let results = await updateResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['component']);
        }
    }

    async function deleteComponent()
    {
        let deleteResults = await fetch(`/component/${props.objectID}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json from results
        let results = await deleteResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['component']);
        }
    }

    async function updateOrder(direction)
    {
        let updateOrderResults = await fetch(`/component/order`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: props.objectID,
                direction: direction,
                version:props.versionID
            })
        });
        let updateOrder = await updateOrderResults.json();
        if(!updateOrder.error){
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['component']);
        }
    }

    async function onMoveUp()
    {
        await updateOrder("up");
    }

    async function onMoveDown()
    {
        await updateOrder("down");
    }

    const [fieldValues,setFieldValues] = useState({
        name: props.title || "",
        description: props.description || "",
        componentPrefix: props.componentPrefix || ""
    });

    function handleChange(event, fieldID)
    {
        let newFields = {...fieldValues};
        let value = event.target.value;
        newFields[fieldID] = value;
        setFieldValues(newFields);
    }

    const [saveDisplay,setSaveDisplay] = useState("none");

    const [unsavedChanges,setUnsavedChanges] = useState([]);

    function compareCurrentValueWithInitial(event)
    {
        let initial = event.target.getAttribute("initial");
        let value = event.target.value;
        let id = event.target.getAttribute("id");
        if(value !== initial && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,event.target];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(value == initial)
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    //remove current selection from array
                    const updatedUnsavedChanges = unsavedChanges;
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
            if(unsavedChanges.length === 0)
            {
                setSaveDisplay("none");
            }
        }
    }

    function formatDateTime(timestamp)
    {
        let dateObj = new Date(Date.parse(timestamp));
        let hour = dateObj.getHours();
        let result = hour;
        let minutes = dateObj.getMinutes();
        let ext = '';
        if(hour > 12)
        {
            ext = 'PM';
            hour = (hour - 12);
            if(hour < 10)
            {
                result = "0" + hour;
            }else if(hour == 12)
            {
                hour = "00";
                ext = 'AM';
            }
        }
        else if(hour < 12)
        {
            result = ((hour < 10) ? "0" + hour : hour);
            ext = 'AM';
        }else if(hour == 12)
        {
            ext = 'PM';
        }
        if(minutes < 10){
            minutes = "0" + minutes; 
        }
        return `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()} ${result}:${minutes} ${ext}`;
    }

    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

    return (
        <Accordion id={props.id} defaultActiveKey="1" className="mb-2" ref={ref}>
            <Card>
                <Card.Header style={{padding:"0",margin:"0",display:"flex"}} className="my-auto">
                    <button style={{width:"100%",height:"50px",paddingLeft:"15px",border:"none",textAlign:"left"}} onClick={()=>{window.location = `/viewcomponent/${props.versionID}/${props.objectID}`}}>{props.elementCount ? `${props.title} - Elements: ${props.elementCount}` : props.title}</button>
                    <ContextAwareToggle className="my-auto" eventKey="0"/>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px"}}>
                            <div>
                                <CustomButton width="33px" height="33px" image={upArrow} onClick={onMoveUp} marginRight="10px"/>
                                <CustomButton width="33px" height="33px" image={downArrow} onClick={onMoveDown}/>
                            </div>
                            <div>
                                <Button variant='success' size='sm' style={{marginRight:"10px",display:saveDisplay}} onClick={updateComponent}>Save</Button>
                                <Button variant='secondary' size='sm' onClick={deleteComponent}>Delete</Button>
                            </div>
                        </div>
                        <FormInput id='name' title='Name' value={fieldValues['name']} marginRight='10px' initial={props.title} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='description' title='Description' value={fieldValues['description']} initial={props.description} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='componentPrefix' title='Component Prefix' value={fieldValues['componentPrefix']} initial={props.componentPrefix} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",fontWeight:"lighter"}}>
                            {
                                props.createdAt && 
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Created: ${formatDateTime(props.createdAt)}`}</h6>
                            }
                            {
                                props.updatedAt &&
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Updated: ${formatDateTime(props.updatedAt)}`}</h6>
                            }
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
})

export default AppComponentCard;