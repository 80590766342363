import React, { useEffect, useState } from 'react';
import CheckAuth from '../components/CheckAuth';
import { useParams } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import {Alert, Button, Spinner, DropdownButton, Dropdown} from 'react-bootstrap';
import Navbar from '../components/Navbar';
//import bootstrap
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
//import components
import AppComponentModal from '../components/AppComponentModal';
import ITRModal from '../components/itrs/ITRModal';
import PSLModal from '../components/psls/PSLModal';
import AppComponentCard from '../components/AppComponentCard';
import ITRCard from '../components/itrs/ITRCard';
import PSLCard from '../components/psls/PSLCard';
import EmployeeCard from '../components/employees/EmployeeCard';
import EmployeeModal from '../components/employees/EmployeeModal';
import FilterCard from '../components/filters/FilterCard';
import FilterModal from '../components/filters/FilterModal';

const AppComponent = (props) => {
    const evalAuth = async() => {
        let auth = await CheckAuth();
        if(auth)
        {
           await returnPSLOptions();
        }else
        {
            window.location = "/login";
        }
    }
    //load current table view
    async function loadTableView(newTableViews)
    {
        //loop through load table
        for(let a in tableViews)
        {
            //check each object in table views for an update flag
            if(tableViews[a].update)
            {
                newTableViews[a].data = await returnView(a); 
                newTableViews[a].update = false;
            }
        }
        setTableViews(newTableViews);
        //update is loading
        setIsLoading(false);
    }
    //keep track of when loading initial views is finished
    const [isLoading, setIsLoading] = useState(true)
    //store refernces to component cards
    const [componentCards,setComponentCards] = useState([]);
    //TODO: setup state to avoid making unecessary calls
    const [elementNames, setElementNames] = useState([]);
    //send call to retrieve all element names
    const retrieveElementNames = async(ids) => {
        console.log(ids);
        let elementNamesResults = await fetch(`/element/names`,{
            method:"POST",
            headers:{
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                elementIDs: ids
            })
        });
        //parse json from results
        let elementNamesArray = await elementNamesResults.json();
        if(elementNamesArray.error){
            return [];
        }
        return elementNamesArray;
    }

    //run api call to server that returns app components from mongo
    const returnView = async(view) => 
    {
        let fetchResults = await fetch(`/${view}/${version}`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        });
        //
        let results = await fetchResults.json();
        console.log(results);
        //check results
        if(results.error === undefined)
        {
            //create array of references
            let newReferences = [];
            //loop through result
            let viewList = await Promise.all(results.map(async(item,index)=>{
                //format each element in the array as a component
                if(view === "component")
                {
                    const ref = React.createRef();
                    newReferences.push(ref);
                    return <AppComponentCard ref={ref} eventKey={`${index}-${item.name}`} key={`${index}-${item.name}`} title={item.name} description={item.description} componentPrefix={item.componentPrefix} createdAt={item.createdAt} updatedAt={item.updatedAt} objectID={item._id} resetView={handleTableView} versionID={version}/>
                }else if(view === "itr")
                {
                    let namedElementsList = [];
                    if(item.elements)
                    {
                        if(item.elements.length>0)
                        {
                            console.log(item.elements);
                            let elementNames = await retrieveElementNames(item.elements);
                            namedElementsList = elementNames.map((item, index)=>{
                                return `${item.componentPrefix} - ${item.fieldDisplayName}`;
                            })
                        }
                    }
                    //compare psls vs available options
                    let availablePSLS = item.psls.filter(function(pslItem){
                        return pslOptions.filter(e => e.key === pslItem).length > 0;
                    });
                    return <ITRCard key={index} id={index} title={item.Rec_Service_Name} standardNotes={item.Standard_Notes} serviceNotes={item.Service_Notes} manualDisclaimer={item.Man_Disclaimer} accessDisclaimer={item.AccessDisclaimer} relatedPSLS={availablePSLS || []} relatedElements={item.elements || []} namedElementsList={namedElementsList} objectID={item._id} resetView={handleTableView} createdAt={item.createdAt} updatedAt={item.updatedAt} versionID={version} pslOptions={pslOptions}/>;
                }else if(view === "employee")
                {
                    return <EmployeeCard key={`${index}-${item.FullName}`} item={item} resetView={handleTableView} />
                }else if(view === "filter")
                {
                    //compare components vs available options
                    let availableComponents = item.components.filter(function(componentItem){
                        return componentOptions.filter(e => e.key === componentItem).length > 0;
                    });
                    return <FilterCard key={`${index}-${item.name}`} item={item} componentOptions={componentOptions} relatedComponents={availableComponents || []} resetView={handleTableView} versionID={version}/>
                }
                else
                {
                    let namedITRsList = [];
                    if(item.itrs)
                    {
                        for(let a = 0;a < item.itrs.length;a++)
                        {
                            //get itr item from table view
                            let itrList = tableViews.itr.data;
                            for(let b = 0;b < itrList.length;b++)
                            {
                                if(itrList[b].props.objectID === item.itrs[a])
                                {
                                    namedITRsList.push(itrList[b].props.title);
                                }
                            }
                        }
                    }
                    return <PSLCard key={index} item={item} resetView={handleTableView} versionID={version} namedITRsList={namedITRsList}/>
                }
            }));
            //update references
            setComponentCards(newReferences);
            //update is loading variable
            return viewList;
        }else
        {
            return [];
        }
    }

    const returnPSLOptions = async() => {
        let fetchResults = await fetch(`/psl/${version}`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        });
    
        let results = await fetchResults.json();
        console.log(results)
        //check results
        if(!results.error)
        {
            //loop through result
            let pslOptionsArray = results.map((item,index)=>{
                return {key:item._id,label:item.Product_Or_Service}
            });
            setPSLOptions(pslOptionsArray);
        }
    }

    const returnComponentOptions = async() => {
        let fetchResults = await fetch(`/component/${version}`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        });
    
        let results = await fetchResults.json();
        //check results
        if(!results.error)
        {
            //loop through result
            let componentArray = results.map((item,index)=>{
                return {key:item._id,label:item.name}
            });
            setComponentOptions(componentArray);
        }
    }

    //get version id from params
    const {version} = useParams();
    //setup state for tables view
    const [tableViews, setTableViews] = useState({
        component: {
            data: [],
            update: true
        },
        itr: {
            data: [],
            update: true
        },
        psl: {
            data:[],
            update: true
        },
        employee: {
            data: [],
            update: true
        },
        filter: {
            data: [],
            update: true
        }
    });
    //setup active view state
    const [activeView, setActiveView] = useState("component");
    //setup modal show/hide toggling
    const [modalShow, setModalShow] = useState(false);
    //setup state to handle button variant
    const [buttonVariants,setButtonVariants] = useState({
        component: "light",
        itr: "secondary",
        psl: "secondary",
        employee: "secondary",
        filter: "secondary"
    });
    //setup state to hold product service options
    const [pslOptions,setPSLOptions] = useState(null);
    //setup state to hold component options
    const [componentOptions,setComponentOptions] = useState([]);
    //setup state to handle name of dropdown button for mobile
    const [dropdownTitle, setDropdownTitle] = useState("Components")

    function handleTableView(viewArray) //expects viewArray=>['',]
    {
        let newTableViews = {
            ...tableViews
        }
        //loop through array
        for(let a = 0;a < viewArray.length;a++)
        {
            newTableViews[viewArray[a]].update = true;
        }
        loadTableView(newTableViews);
    }

    async function handleVariant(event)
    {
        let newButtonVariants = {...buttonVariants};
        for(let a in newButtonVariants)
        {
            newButtonVariants[a] = "secondary"
        }
        newButtonVariants[event.target.id] = "light";
        setButtonVariants(newButtonVariants);
        setActiveView(event.target.id);
    }

    useEffect(()=>{
        //loads default active view into page
        evalAuth();
    },[]);

    useEffect(async ()=>{
        //only continue after psl options has been set from api call
        if(pslOptions)
        {
            returnComponentOptions();
        }
    },[pslOptions])

    useEffect(()=>{
        //only continue to load table view after psl options has been set from api call
        if(componentOptions.length>0){
            loadTableView({...tableViews});
        }
    },[componentOptions])

    return (
        <React.Fragment>
            {
                activeView === "component" ? <AppComponentModal show={modalShow} onHide={() => setModalShow(false)} resetView={handleTableView} version={version}/> 
                : activeView === "itr" ? <ITRModal show={modalShow} onHide={() => setModalShow(false)} resetView={handleTableView} version={version}/> 
                : activeView === "psl" ? <PSLModal show={modalShow} onHide={() => setModalShow(false)} resetView={handleTableView} version={version}/>
                : activeView === "employee" ? <EmployeeModal show={modalShow} onHide={() => setModalShow(false)} resetView={handleTableView} version={version}/>
                : <FilterModal show={modalShow} onHide={() => setModalShow(false)} resetView={handleTableView} version={version}/>
            }
                <Navbar secondaryName={"Backend"}/>
                {/* Main Container */}
                <div className="container-fluid pt-3">
                    {/* Section Divider */}
                    <div className="sectionDivider pb-2 pt-1" style={{display:"flex",justifyContent:"space-between"}}>
                        {
                            isMobile === false ? <div style={{display:"flex"}}>
                                <Button id="component" variant={buttonVariants.component} size="lg" className="me-2" onClick={(event)=>{handleVariant(event)}}>Components</Button>
                                <Button id="itr" variant={buttonVariants.itr} size="lg" className="me-2" onClick={(event)=>{handleVariant(event)}}>Recommendations</Button>
                                <Button id="psl" variant={buttonVariants.psl} size="lg" className="me-2" onClick={(event)=>{handleVariant(event)}}>Products / Services</Button>
                                <Button id="filter" variant={buttonVariants.filter} size="lg" className="me-2" onClick={(event)=>{handleVariant(event)}}>Filters</Button>
                                <Button id="employee" variant={buttonVariants.employee} size="lg"  onClick={(event)=>{handleVariant(event)}}>Employees</Button>
                                <button id="createNewNode" type="button" className="btn btn-primary btn-md my-auto ms-3" onClick={() => setModalShow(true)}>+ New</button> 
                            </div> : <div style={{display:"flex"}}><DropdownButton size="lg" title={dropdownTitle} variant="secondary">
                                <Dropdown.Item id="component" size="lg" onClick={(event)=>{setDropdownTitle(event.target.text);handleVariant(event)}}>Components</Dropdown.Item>
                                <Dropdown.Item id="itr" size="lg" onClick={(event)=>{setDropdownTitle(event.target.text);handleVariant(event)}}>Recommendations</Dropdown.Item>
                                <Dropdown.Item id="psl" size="lg" onClick={(event)=>{setDropdownTitle(event.target.text);handleVariant(event)}}>Products / Services</Dropdown.Item>
                                <Dropdown.Item id="filter" size="lg" onClick={(event)=>{setDropdownTitle(event.target.text);handleVariant(event)}}>Filters</Dropdown.Item>
                                <Dropdown.Item id="employee" size="lg" onClick={(event)=>{setDropdownTitle(event.target.text);handleVariant(event)}}>Employees</Dropdown.Item>
                                </DropdownButton><button id="createNewNode" type="button" className="btn btn-primary btn-md my-auto ms-3" onClick={() => setModalShow(true)}>+ New</button></div>
                        }

                    </div>
                    {/* Card Section */}
                    <div className="my-3">
                        {
                            tableViews[activeView].data.length > 0 ? tableViews[activeView].data : <Alert style={{display: 'flex'}} key='1' variant='info'>
                                {
                                    isLoading && <Spinner className="my-auto me-4" animation="border"/>
                                }
                                {
                                    isLoading ? <h5 className="my-auto">Loading records...</h5> : "No records found" 
                                }
                                </Alert>
                        }
                    </div>
                </div>
        </React.Fragment>
    );
  };

export default AppComponent;