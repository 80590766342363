import React, {useState, useEffect} from "react";
import {Button, Accordion, Badge, Card} from 'react-bootstrap';
import FormInput from "../FormInput";
import { isMobile } from "react-device-detect";
//import style
import '../../styles/custom-form.css';

function EmployeeCard(props)
{
    async function updateDocument()
    {
        let updateResults = await fetch(`/employee/${props.item._id}`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...fieldValues,version:props.versionID})
        });
        //get json from results
        let results = await updateResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['employee']);
        }
    }

    async function deleteDocument()
    {
        let deleteResults = await fetch(`/employee/${props.item._id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json from results
        let results = await deleteResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['employee']);
        }
    }

    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

    const [fieldValues,setFieldValues] = useState({
        "Permission": props.item.Permission || "",
        "ActiveStatus": props.item.ActiveStatus || "",
        "TechClass": props.item.TechClass || "",
        "PIN": props.item.PIN || "",
        "Position": props.item.Position || "", 
        "Branch_ID": props.item.Branch_ID || "",
        "FullName": props.item.FullName || "",
        "FirstName": props.item.FirstName || "",
        "LastName": props.item.LastName || "",
        "BitrixID": props.item.BitrixID || "",
        "Headshot": props.item.Headshot || ""
    });

    function handleChange(event, fieldID)
    {
        let newFields = {...fieldValues};
        let value = event.target.value;
        newFields[fieldID] = value;
        setFieldValues(newFields);
    }

    const [saveDisplay,setSaveDisplay] = useState("none");

    const [unsavedChanges,setUnsavedChanges] = useState([]);

    function compareCurrentValueWithInitial(event)
    {
        let initial = event.target.getAttribute("initial");
        let value = event.target.value;
        let id = event.target.getAttribute("id");
        if(value !== initial && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,event.target];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(value == initial)
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(value !== initial)
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,event.target];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function checkUnsavedChanges()
    {
        if(unsavedChanges.length === 0)
        {
            setSaveDisplay("none");
        }
    }

    useEffect(()=>{
        checkUnsavedChanges();
    },[unsavedChanges])

    function formatDateTime(timestamp)
    {
        let dateObj = new Date(Date.parse(timestamp));
        let hour = dateObj.getHours();
        let result = hour;
        let minutes = dateObj.getMinutes();
        let ext = '';
        if(hour > 12)
        {
            ext = 'PM';
            hour = (hour - 12);
            if(hour < 10)
            {
                result = "0" + hour;
            }else if(hour == 12)
            {
                hour = "00";
                ext = 'AM';
            }
        }
        else if(hour < 12)
        {
            result = ((hour < 10) ? "0" + hour : hour);
            ext = 'AM';
        }else if(hour == 12)
        {
            ext = 'PM';
        }
        if(minutes < 10){
            minutes = "0" + minutes; 
        }
        return `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()} ${result}:${minutes} ${ext}`;
    }

    return (
        <Accordion defaultActiveKey="1" className="mb-2">
            <Accordion.Item>
                <Accordion.Header style={{padding:"0",margin:"0",display:"flex"}} className="my-auto">
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p className="my-auto">{fieldValues['FullName']}</p>
                    </div>
                </Accordion.Header>
                    <Accordion.Body>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"15px"}}>
                            {
                                props.item.Headshot ? <img style={{display:"inline-block",width:"80px",height:"80px",borderRadius:"50%"}} src={props.item.Headshot}/> : <div/>
                            }
                            <div style={{display:"flex",justifyContent:"right"}}>
                                <Button className="mb-auto align-top" variant='success' size='sm' style={{marginRight:"10px",display:saveDisplay}} onClick={updateDocument}>Save</Button>
                                <Button className="mb-auto align-top" variant='secondary' size='sm' onClick={deleteDocument}>Delete</Button>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='FirstName' title='First Name' value={fieldValues['FirstName']} marginRight='10px' onChange={(event)=>handleChange(event,"FirstName")}/>
                            <FormInput id='LastName' title='Last Name' value={fieldValues['LastName']} marginRight='10px' onChange={(event)=>handleChange(event,"LastName")}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='PIN' title='PIN' value={fieldValues['PIN']} marginRight='10px' initial={props.item.PIN} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Permission' title='Permission' value={fieldValues['Permission']} marginRight='10px' initial={props.item.Permission} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Position' title='Position' value={fieldValues['Position']} marginRight='10px' initial={props.item.Position} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='TechClass' title='Tech Class' value={fieldValues['TechClass']} initial={props.item.TechClass} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Branch_ID' title='Branch ID' value={fieldValues['Branch_ID']} marginRight='10px' initial={props.item.Branch_ID} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='BitrixID' title='Bitrix ID' value={fieldValues['BitrixID']} initial={props.item.BitrixID} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Headshot' title='Headshot URL' value={fieldValues['Headshot']} initial={props.item.Headshot} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",fontWeight:"lighter"}}>
                            {
                                props.createdAt && 
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Created: ${formatDateTime(props.createdAt)}`}</h6>
                            }
                            {
                                props.updatedAt &&
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Updated: ${formatDateTime(props.updatedAt)}`}</h6>
                            }
                        </div>
                    </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default EmployeeCard;