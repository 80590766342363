import React, { useEffect, useState } from 'react';
import CheckAuth from '../components/CheckAuth';
import Navbar from '../components/Navbar';
//import bootstrap
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
//import components
import VersionModal from '../components/VersionModal';
import VersionCard from '../components/VersionCard';

const AppVersions = () => {
    const evalAuth = async() => {
        let auth = await CheckAuth();
        if(auth)
        {
           await returnVersions();
        }else
        {
            window.location = "/login";
        }
    }

    //run api call to server that returns app versions from mongo
    const returnVersions = async() => {
        let fetchResults = await fetch("/version",{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        });
        //turn results into json
        let results = await fetchResults.json();
        //validate results
        if(!results.error)
        {
            //loop through result
            let versionComponentsList = results.map((item,index)=>{
                //format each element in the array as a component
                return <VersionCard key={`${item.name}-${index}`} title={item.name} description={item.description} source={item.source} createdAt={item.createdAt} updatedAt={item.updatedAt} active={item.active} objectID={item._id} resetVersions={returnVersions}/>
            });
            setVersionComponents(versionComponentsList);
            let versionOptionList = results.map((item,index)=>{
                return <option key={index} value={item._id}>{item.name}</option>
            });
            setVersionOptions(versionOptionList);
        }
    }

    const [versionOptions,setVersionOptions] = useState([]);
    const [versionComponents,setVersionComponents] = useState([]);

    useEffect(()=>{
        evalAuth();
    },[])


    const [modalShow, setModalShow] = React.useState(false);

    return (
        <React.Fragment>
                <VersionModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    versionList={versionOptions}
                    resetVersions={returnVersions}
                />
                <Navbar secondaryName={"Backend"}/>
                {/* Main Container */}
                <div className="container-fluid pt-3">
                    {/* Node Section Divider */}
                    <div className="sectionDivider pb-3 pt-1" style={{display:"flex",justifyContent:"space-between"}}>
                        <div style={{display:"flex"}}>
                            <h2 className="my-auto sectionTitle">Versions</h2>
                            <button id="createNewNode" type="button" className="btn btn-primary btn-md my-auto ms-3" onClick={() => setModalShow(true)}>+ New</button> 
                        </div>
                    </div>
                    {/* Node Section */}
                    <div className="my-3" id="nodeSection">
                        {versionComponents}
                    </div>
                </div>
        </React.Fragment>
    );
};

export default AppVersions;