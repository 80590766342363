import React, {useState, useEffect} from "react";
import {Button, Accordion, Badge, Card} from 'react-bootstrap';
import FormInput from "../FormInput";
import { isMobile } from "react-device-detect";
//import style
import '../../styles/custom-form.css';
import BadgeList from "../BadgeList";

function PSLCard(props)
{
    async function updateComponent()
    {
        let updateResults = await fetch(`/psl/${props.item._id}`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...fieldValues,version:props.versionID})
        });
        //get json from results
        let results = await updateResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['psl']);
        }
    }

    async function deleteComponent()
    {
        let deleteResults = await fetch(`/psl/${props.item._id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json from results
        let results = await deleteResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['psl']);
        }
    }

    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

    const [fieldValues,setFieldValues] = useState({
        Product_Or_Service: props.item.Product_Or_Service || "",
        Simple_Name: props.item.Simple_Name || "",
        Variant: props.item.Variant || "",
        Price: props.item.Price || "",
        Unit: props.item.Unit || "",
        Quantity: props.item.Quantity || "",
        Common_Name: props.item.Common_Name || "",
        Common_Issues: props.item.Common_Issues || "",
        Description: props.item.Description || "",
        Make_Brand: props.item.Make_Brand || "",
        Model: props.item.Model || "",
        Attrib_Length: props.item.Attrib_Length || "",
        Attrib_Width: props.item.Attrib_Width || "",
        Attrib_Height: props.item.Attrib_Height || "",
        Attrib_Color: props.item.Attrib_Color || "",
        Attrib_RelatedComponent: props.item.Attrib_RelatedComponent || "",
        Sales_Snippet: props.item.Sales_Snippet || "",
        Img_URL: props.item.Img_URL || "",
        crmID: props.item.crmID || "",
        posID: props.item.posID || "",
        SKU: props.item.SKU || "",
        Notes_Disclaimer: props.item.Notes_Disclaimer || "",
    });

    function handleChange(event, fieldID)
    {
        let newFields = {...fieldValues};
        let value = event.target.value;
        newFields[fieldID] = value;
        setFieldValues(newFields);
    }

    const [saveDisplay,setSaveDisplay] = useState("none");

    const [unsavedChanges,setUnsavedChanges] = useState([]);

    function compareCurrentValueWithInitial(event)
    {
        let initial = event.target.getAttribute("initial");
        let value = event.target.value;
        let id = event.target.getAttribute("id");
        if(value !== initial && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,event.target];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(value == initial)
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(value !== initial)
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,event.target];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function checkUnsavedChanges()
    {
        if(unsavedChanges.length === 0)
        {
            setSaveDisplay("none");
        }
    }

    useEffect(()=>{
        checkUnsavedChanges();
    },[unsavedChanges])

    function formatDateTime(timestamp)
    {
        let dateObj = new Date(Date.parse(timestamp));
        let hour = dateObj.getHours();
        let result = hour;
        let minutes = dateObj.getMinutes();
        let ext = '';
        if(hour > 12)
        {
            ext = 'PM';
            hour = (hour - 12);
            if(hour < 10)
            {
                result = "0" + hour;
            }else if(hour == 12)
            {
                hour = "00";
                ext = 'AM';
            }
        }
        else if(hour < 12)
        {
            result = ((hour < 10) ? "0" + hour : hour);
            ext = 'AM';
        }else if(hour == 12)
        {
            ext = 'PM';
        }
        if(minutes < 10){
            minutes = "0" + minutes; 
        }
        return `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()} ${result}:${minutes} ${ext}`;
    }

    return (
        <Accordion defaultActiveKey="1" className="mb-2">
            <Accordion.Item>
                <Accordion.Header style={{padding:"0",margin:"0",display:"flex"}} className="my-auto">
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p className="my-auto">{fieldValues['Product_Or_Service']}</p>
                    </div>
                </Accordion.Header>
                    <Accordion.Body>
                        <div style={{display:"flex",justifyContent:"right",marginBottom:"10px"}}>
                            <Button variant='success' size='sm' style={{marginRight:"10px",display:saveDisplay}} onClick={updateComponent}>Save</Button>
                            <Button variant='secondary' size='sm' onClick={deleteComponent}>Delete</Button>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Product_Or_Service' title='Product / Service Name' value={fieldValues['Product_Or_Service']} marginRight='10px' initial={props.item.Product_Or_Service} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Simple_Name' title='Simple Name' value={fieldValues['Simple_Name']} initial={props.item.Simple_Name} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Variant' title='Variant' value={fieldValues['Variant']} marginRight='10px' initial={props.item.Variant} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Price' title='Price' value={fieldValues['Price']} initial={props.item.Price} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Unit' title='Unit' value={fieldValues['Unit']} marginRight='10px' initial={props.item.Unit} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Quantity' title='Quantity' value={fieldValues['Quantity']} initial={props.item.Quantity} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Common_Name' title='Common Name' value={fieldValues['Common_Name']} marginRight='10px' initial={props.item.Common_Name} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Common_Issues' title='Common Issues' value={fieldValues['Common_Issues']} initial={props.item.Common_Issues} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Description' title='Description' value={fieldValues['Description']} marginRight='10px' initial={props.item.Description} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Make_Brand' title='Make / Brand' value={fieldValues['Make_Brand']} initial={props.item.Make_Brand} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Model' title='Model' value={fieldValues['Model']} marginRight='10px' initial={props.item.Model} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Attrib_Length' title='Length Attribute' value={fieldValues['Attrib_Length']} initial={props.item.Attrib_Length} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Attrib_Width' title='Width Attribute' value={fieldValues['Attrib_Width']} marginRight='10px' initial={props.item.Attrib_Width} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Attrib_Height' title='Height Attribute' value={fieldValues['Attrib_Height']} initial={props.item.Attrib_Height} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Attrib_Color' title='Color Attribute' value={fieldValues['Attrib_Color']} marginRight='10px' initial={props.item.Attrib_Color} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Attrib_RelatedComponent' title='Related Component Attribute' value={fieldValues['Attrib_RelatedComponent']} initial={props.item.Attrib_RelatedComponent} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='Sales_Snippet' title='Sales Snippet' value={fieldValues['Sales_Snippet']} marginRight='10px' initial={props.item.Sales_Snippet} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Img_URL' title='Img URL' value={fieldValues['Img_URL']} initial={props.item.Img_URL} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='crmID' title='CRM ID' value={fieldValues['crmID']} marginRight='10px' initial={props.item.crmID} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='posID' title='Point of Sale ID' value={fieldValues['posID']} initial={props.item.posID} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='SKU' title='SKU' value={fieldValues['SKU']} marginRight='10px' initial={props.item.SKU} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <FormInput id='Notes_Disclaimer' title='Notes / Disclaimer' value={fieldValues['Notes_Disclaimer']} initial={props.item.Notes_Disclaimer} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        </div>
                        {
                            props.namedITRsList.length > 0 &&
                            <BadgeList list={props.namedITRsList} title='Recommendations' bg='secondary'/>
                        }
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",fontWeight:"lighter"}}>
                            {
                                props.createdAt && 
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Created: ${formatDateTime(props.createdAt)}`}</h6>
                            }
                            {
                                props.updatedAt &&
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Updated: ${formatDateTime(props.updatedAt)}`}</h6>
                            }
                        </div>
                    </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default PSLCard;