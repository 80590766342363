import React,{useState} from 'react';
import {Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
//import 

const Login = () => {

    async function OnLogin(){
        let loginResults = await fetch(`/user/login`,{
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                password: password
            }),
            credentials: 'include',
            mode: 'cors'
        });
        //get json results
        let login = await loginResults.json();
        //check login message
        if(login.error)
        {
            setAlertMessage(login.error);
        }else
        {
            if(login.message !== "valid user")
            {
                setAlertMessage(login.message);
            }else
            {
                setAlertMessage("");
                window.location="/"
            }
        }
    }

    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [alertMessage,setAlertMessage] = useState("");

    return (
    <Container fluid>
        <Row style={{marginTop: '50px'}}>
            <Col><h1 style={{textAlign: 'center'}}>Chimney Track Admin Login</h1></Col>
        </Row>
        <Row style={{marginTop: '30px'}}>
            <Col><h3>Username</h3></Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
            <Col>
                <Form.Control value={username} size="lg" type="text" placeholder="Username" onChange={(event)=>{setUsername(event.target.value)}}/>
            </Col>
        </Row>
        <Row style={{marginTop: '30px'}}>
            <Col><h3>Password</h3></Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
            <Col>
                <Form.Control value={password} size="lg" type="password" placeholder="Password" onChange={(event)=>{setPassword(event.target.value)}}/>
            </Col>
        </Row>
        <Row style={{marginTop: '30px'}}>
            <Col style={{justifyContent: 'center',alignItems: 'center'}}>
                <Button size="lg" variant="secondary" onClick={OnLogin}>Login</Button>
            </Col>
        </Row>
        {
        alertMessage !== "" &&
        <Row style={{marginTop: '30px',marginLeft: '1px',marginRight: '1px'}}>
            <Alert variant="danger">{alertMessage}</Alert>
        </Row>
        }
    </Container>
    );
  };

export default Login;