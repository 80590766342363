import React, { useState } from "react";
import { InputGroup, FormControl, Modal, Button} from 'react-bootstrap';

function PSLModal(props)
{
  async function createComponent()
  {
    let fetchResults = await fetch("/psl",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify(fieldValues)
        });
        //turn results into json
        let results = await fetchResults.json();
        //validate results
        if(!results.error)
        {
            //refresh versions page to show newly created version
            props.resetView(['psl']);
        }
        props.onHide();
  }

  const [fieldValues,setFieldValues] = useState({
    Product_Or_Service:"",
    Simple_Name: "",
    Variant: "",
    Price: "",
    Unit: "",
    Quantity: "",
    Common_Name:"",
    Common_Issues:"",
    Description:"",
    Make_Brand:"",
    Model:"",
    Attrib_Length:"",
    Attrib_Width:"",
    Attrib_Height:"",
    Attrib_Color:"",
    Attrib_RelatedComponent:"",
    Sales_Snippet:"",
    Img_URL:"",
    crmID:"",
    posID:"",
    SKU:"",
    Notes_Disclaimer:"",
    version: props.version
  })

  function handleChange(event)
  {
    console.log("change handled");
    let newFields = {...fieldValues};
    newFields[event.target.id] = event.target.value;
    setFieldValues(newFields);
  }

  return (
  <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Create Product / Service
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InputGroup className="mb-2">
          <InputGroup.Text>Name</InputGroup.Text>
          <FormControl id="Product_Or_Service" value={fieldValues["Product_Or_Service"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Simple Name</InputGroup.Text>
          <FormControl id="Simple_Name" value={fieldValues["Simple_Name"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Variant</InputGroup.Text>
          <FormControl id="Variant" value={fieldValues["Variant"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Price</InputGroup.Text>
          <FormControl id="Price" value={fieldValues["Price"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Unit</InputGroup.Text>
          <FormControl id="Unit" value={fieldValues["Unit"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Quantity</InputGroup.Text>
          <FormControl id="Quantity" value={fieldValues["Quantity"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Common Name</InputGroup.Text>
          <FormControl id="Common_Name" value={fieldValues["Common_Name"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Common Issues</InputGroup.Text>
          <FormControl id="Common_Issues" value={fieldValues["Common_Issues"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Description</InputGroup.Text>
          <FormControl id="Description" value={fieldValues["Description"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Make / Brand</InputGroup.Text>
          <FormControl id="Make_Brand" value={fieldValues["Make_Brand"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Model</InputGroup.Text>
          <FormControl id="Model" value={fieldValues["Model"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Length Attribute</InputGroup.Text>
          <FormControl id="Attrib_Length" value={fieldValues["Attrib_Length"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Width Attribute</InputGroup.Text>
          <FormControl id="Attrib_Width" value={fieldValues["Attrib_Width"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Height Attribute</InputGroup.Text>
          <FormControl id="Attrib_Height" value={fieldValues["Attrib_Height"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Color Attribute</InputGroup.Text>
          <FormControl id="Attrib_Color" value={fieldValues["Attrib_Color"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Related Component Attribute</InputGroup.Text>
          <FormControl id="Attrib_RelatedComponent" value={fieldValues["Attrib_RelatedComponent"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Sales Snippet</InputGroup.Text>
          <FormControl id="Sales_Snippet" value={fieldValues["Sales_Snippet"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Img URL</InputGroup.Text>
          <FormControl id="Img_URL" value={fieldValues["Img_URL"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>CRM ID</InputGroup.Text>
          <FormControl id="crmID" value={fieldValues["crmID"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Point of Sale ID</InputGroup.Text>
          <FormControl id="posID" value={fieldValues["posID"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>SKU</InputGroup.Text>
          <FormControl id="SKU" value={fieldValues["SKU"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup>
          <InputGroup.Text>Notes / Disclaimer</InputGroup.Text>
          <FormControl id="Notes_Disclaimer" value={fieldValues["Notes_Disclaimer"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} variant='secondary'>Cancel</Button>
      <Button onClick={createComponent}>Create</Button>
    </Modal.Footer>
  </Modal>
  );
}

export default PSLModal;