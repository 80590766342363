import React, { useState } from "react";
import { InputGroup, FormControl, Modal, Button, Form} from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import FormInput from "../FormInput";
import ReactQuill from 'react-quill';

function ElementModal(props)
{
    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]

    const sectionTypeOptions = ["Inspection Items","Measurements","Perspective Images","MeasurementUnit","Specifications","Other"]

    async function createElementItem()
    {
        //create body
        let elementBody = {
            ...fieldValues,
            component:props.component,
            componentPrefix:props.componentPrefix
        }
        console.log(elementBody);
        //send api call
        let fetchResults = await fetch("/element",{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(elementBody)
            });
            //turn results into json
            let results = await fetchResults.json();
            //validate results
            if(!results.error)
            {
                //refresh versions page to show newly created version
                props.resetElements();
            }else
            {
            console.log(results.error);
            }
            props.onHide();
    }

  const [fieldValues,setFieldValues] = useState({
    fieldDisplayName: "",
    SQL_Field_Name: "",
    ignoreBool: false,
    prefabType: "",
    prefabValues: "",
    warning: "",
    failedItemDescription: "",
    dependency: "",
    fieldDisplayName: "",
    sectionType: "",
    relationalParent: false,
    relationalChild: "",
    unit: "",
    componentSort: "",
    TBDModifier: "",
    Inspection_Guidelines: "",
    Add_Info: "",
    Pro_Tips: "",
    Custom_Formula: ""
  })

  function handleChange(event)
  {
    let newFields = {...fieldValues};
    newFields[event.target.id] = event.target.value;
    setFieldValues(newFields);
  }

  return (
  <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Create Element
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='fieldDisplayName' title='Display Name' value={fieldValues['fieldDisplayName']} marginRight='10px' onChange={(event,id)=>{handleChange(event,id)}}/>
            <InputGroup className="mb-2">
                <InputGroup.Text>Prefab Type</InputGroup.Text>
                <Form.Select id='prefabType' value={fieldValues['prefabType']} aria-label="Prefab Type" onChange={(event,id)=>{handleChange(event,"prefabType")}}>
                    <option value="">Select a prefab type</option>
                    <option value="Inspection Group">Inspection Group</option>
                    <option value="Select All">Select All</option>
                    <option value="Measurement">Measurement</option>
                    <option value="MeasurementUnit">MeasurementUnit</option>
                    <option value="Short Text">Short Text</option>
                    <option value="Long Text">Long Text</option>
                    <option value="Radio Select">Radio Select</option>
                    <option value="Image Capture">Image Capture</option>
                    <option value="Artwork">Artwork</option>
                    <option value="DiagramName">DiagramName</option>
                </Form.Select>
            </InputGroup>
            {/* <FormInput id='prefabType' title='Prefab Type' value={fieldValues['prefabType']} initial={props.item.prefabType} onChange={(event,id)=>{handleChange(event,id)}}/> */}
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='prefabValues' title='Prefab Values' value={fieldValues['prefabValues']} marginRight='10px' onChange={(event,id)=>{handleChange(event,id)}}/>
            {/* <FormInput id='sectionType' title='Section Type' value={fieldValues['sectionType']} initial={props.item.sectionType} onChange={(event,id)=>{handleChange(event,id)}}/> */}
            <InputGroup className="mb-2">
                <InputGroup.Text>Section Type</InputGroup.Text>
                <Form.Select id='sectionType' value={fieldValues['sectionType']} aria-label="Section Type" onChange={(event,id)=>{handleChange(event,"sectionType")}}>
                    <option value="">Select a section type</option>
                    {sectionTypeOptions.map(function(item,index){
                        return <option key={`${item}-${index}`} value={item}>{item}</option>;
                    })}
                </Form.Select>
                {
                    fieldValues['sectionType'] === "Other" || !sectionTypeOptions.includes(fieldValues['sectionType']) ? <FormControl value={fieldValues['sectionType']} className="ms-3" onChange={(event,id)=>{handleChange(event,'sectionType')}}/> : ""
                }
            </InputGroup>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            {/* <FormInput id='ignoreBool' title='Ignore' value={fieldValues['ignoreBool']} marginRight='10px' initial={props.item.ignoreBool.toString()} onChange={(event,id)=>{handleChange(event,id)}}/> */}
            <InputGroup className="mb-2">
                    <InputGroup.Text style={{borderTopRightRadius: "0.25rem",borderBottomRightRadius: "0.25rem"}}>Ignore</InputGroup.Text>
                    <Form.Check className="ms-2 my-auto" id="ignoreBool" checked={fieldValues["ignoreBool"]} onChange={(event,id)=>{handleChange(event,"ignoreBool")}}/>
            </InputGroup>
            <FormInput id='warning' title='Warning' value={fieldValues['warning']} initial='' onChange={(event,id)=>{handleChange(event,id)}}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            {/* <FormInput id='relationalParent' title='Relational Parent' value={fieldValues['relationalParent']} marginRight='10px' initial={props.item.relationalParent.toString()} onChange={(event,id)=>{handleChange(event,id)}}/> */}
            <InputGroup className="mb-2">
                    <InputGroup.Text style={{borderTopRightRadius: "0.25rem",borderBottomRightRadius: "0.25rem"}}>Relational Parent</InputGroup.Text>
                    <Form.Check className="ms-2 my-auto" id="relationalParent" checked={fieldValues["relationalParent"]} onChange={(event,id)=>{handleChange(event,"relationalParent")}}/>
            </InputGroup>
            <FormInput id='relationalChild' title='Relational Child' value={fieldValues['relationalChild']} onChange={(event,id)=>{handleChange(event,id)}}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='failedItemDescription' title='Failed Item Description' value={fieldValues['failedItemDescription']} marginRight='10px' onChange={(event,id)=>{handleChange(event,id)}}/>
            <FormInput id='dependency' title='Dependency' value={fieldValues['dependency']} onChange={(event,id)=>{handleChange(event,id)}}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='unit' title='Unit' value={fieldValues['unit']} marginRight='10px' onChange={(event,id)=>{handleChange(event,id)}}/>
            <FormInput id='TBDModifier' title='TBD Modifier' value={fieldValues['TBDModifier']} onChange={(event,id)=>{handleChange(event,id)}}/>
        </div>
        <InputGroup.Text style={{borderBottomRightRadius: "0",borderBottomLeftRadius: "0"}}>Inspection Guidelines</InputGroup.Text>
        <ReactQuill modules={modules} formats={formats} id="Inspection_Guidelines" style={{marginBottom: "10px"}} theme="snow" value={fieldValues["Inspection_Guidelines"]} onChange={(event,id)=>handleChange(event,id)}/>
        {/* <InputGroup style={{marginBottom:"10px"}}>
            <InputGroup.Text>Inspection Guidelines</InputGroup.Text>
            <FormControl style={{height:"115px"}} id='Inspection_Guidelines' as="textarea" aria-label="Inspection Guidelines" text={fieldValues['Inspection_Guidelines']} defaultValue={props.item.Inspection_Guidelines} initial={props.item.Inspection_Guidelines} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
        </InputGroup> */}

        <InputGroup.Text style={{borderBottomRightRadius: "0",borderBottomLeftRadius: "0"}}>Additional Information</InputGroup.Text>
        <ReactQuill modules={modules} formats={formats} id="Add_Info" style={{marginBottom: "10px"}} theme="snow" value={fieldValues["Add_Info"]} onChange={(event,id)=>handleChange(event,id)}/>
        {/* <InputGroup style={{marginBottom:"10px"}}>
            <InputGroup.Text>Additional Information</InputGroup.Text>
            <FormControl style={{height:"115px"}} id='Add_Info' as="textarea" aria-label="Additional Information" text={fieldValues['Add_Info']} initial={props.item.Add_Info} defaultValue={props.item.Add_Info} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
        </InputGroup> */}

        <InputGroup.Text style={{borderBottomRightRadius: "0",borderBottomLeftRadius: "0"}}>Pro Tips</InputGroup.Text>
        <ReactQuill modules={modules} formats={formats} id="Pro_Tips" style={{marginBottom: "10px"}} theme="snow" value={fieldValues["Pro_Tips"]} onChange={(event,id)=>handleChange(event,id)}/>
        {/* <InputGroup style={{marginBottom:"10px"}}>
            <InputGroup.Text>Pro Tips</InputGroup.Text>
            <FormControl style={{height:"115px"}} id='Pro_Tips' as="textarea" aria-label="Pro Tips" text={fieldValues['Pro_Tips']} defaultValue={props.item.Pro_Tips} initial={props.item.Pro_Tips} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
        </InputGroup> */}
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='SQL_Field_Name' title='Field Key Name' value={fieldValues['SQL_Field_Name']} marginRight='10px' onChange={(event,id)=>{handleChange(event,id)}}/>
            <FormInput id='Custom_Formula' title='Custom Formula' value={fieldValues['Custom_Formula']} onChange={(event,id)=>{handleChange(event,id)}}/>
            {/* <FormInput id='Pro_Tips' title='Pro Tips' value={fieldValues['Pro_Tips']} marginRight='10px' initial={props.item.Pro_Tips} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/> */}
        </div>


      {/* <InputGroup className="mb-2">
          <InputGroup.Text>Pro Tips</InputGroup.Text>
          <FormControl id="Pro_Tips" value={fieldValues["Pro_Tips"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Custom Formula</InputGroup.Text>
          <FormControl id="Custom_Formula" value={fieldValues["Custom_Formula"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Field Key Name</InputGroup.Text>
          <FormControl id="SQL_Field_Name" value={fieldValues["SQL_Field_Name"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup> */}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} variant='secondary'>Cancel</Button>
      <Button onClick={createElementItem}>Create</Button>
    </Modal.Footer>
  </Modal>
  );
}

export default ElementModal;