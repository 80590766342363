import React from 'react';
import {Button} from 'react-bootstrap';

function Navbar({secondaryName=""})
{
    async function onLogout(){
        let logoutResults = await fetch(`/user/logout`,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: "include"
        })
        window.location = "/login";
    }

    return (
        <nav className="navbar navbar-dark bg-dark" style={{width:'100%'}}>
        <div className="container-fluid">
            <h1 className="navbar-brand my-auto">Chimney Track</h1>
            {
                secondaryName !== "" &&
                <h1 className="navbar-brand my-auto" style={{textAlign:"right",marginRight:"0"}}>{secondaryName}</h1>
            }
            <Button variant="outline-light" onClick={onLogout}>Logout</Button>
        </div>
      </nav>
    )
}

export default Navbar;
