import React, {useContext, useState, useRef, useEffect} from "react";
import { useHistory } from "react-router";
import {Button, Accordion} from 'react-bootstrap';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import FormInput from "../FormInput";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import BadgeList from "../BadgeList";
//import style
import '../../styles/custom-form.css';

function ITRCard(props)
{
    async function updateComponent()
    {
        let updateResults = await fetch(`/itr/${props.objectID}`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...fieldValues,version:props.versionID})
        });
        //get json from results
        let results = await updateResults.json();
        //check results
        if(!results.error)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['psl','itr']);
        }else
        {
            console.log(results.error);
        }
    }

    async function deleteComponent()
    {
        let deleteResults = await fetch(`/itr/${props.objectID}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json from results
        let results = await deleteResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['psl','itr']);
        }
    }

    const [fieldValues,setFieldValues] = useState({
        Rec_Service_Name: props.title || "",
        Standard_Notes: props.standardNotes || "",
        Service_Notes: props.serviceNotes || "",
        Man_Disclaimer: props.manualDisclaimer || "",
        AccessDisclaimer: props.accessDisclaimer || "",
        psls: props.relatedPSLS || []
    });

    function handleChange(event, fieldID)
    {
        let newFields = {...fieldValues};
        let value = event.target.value;
        newFields[fieldID] = value;
        setFieldValues(newFields);
    }

    function handleMutliselectChange(selected)
    {
        let newFields = {...fieldValues};
        newFields["psls"] = selected;
        setFieldValues(newFields); 
    }

    const [saveDisplay,setSaveDisplay] = useState("none");

    const [unsavedChanges,setUnsavedChanges] = useState([]);

    const [pslsList, setPSLSList] = useState(props.relatedPSLS);

    function compareCurrentValueWithInitial(event)
    {
        let initial = event.target.getAttribute("initial");
        let value = event.target.value;
        let id = event.target.getAttribute("id");
        if(value !== initial && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,event.target];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(value == initial)
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(value !== initial)
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,event.target];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function compareMultiSelectValue(selected)
    {
        //compare two arrays using json stringify
        console.log(JSON.stringify(selected) + " vs " + JSON.stringify(props.relatedPSLS));
        if(JSON.stringify(selected) != JSON.stringify(props.relatedPSLS) && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,{
                getAttribute: function(id)
                {
                    return "psls";
                }
            }];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(JSON.stringify(selected) == JSON.stringify(props.relatedPSLS))
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === "psls")
                {
                    console.log("remove multiselect");
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(JSON.stringify(selected) != JSON.stringify(props.relatedPSLS))
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === "psls")
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,{
                    getAttribute: function(id)
                    {
                        return "psls";
                    }
                }];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function checkUnsavedChanges()
    {
        if(unsavedChanges.length === 0)
        {
            setSaveDisplay("none");
        }
    }

    useEffect(()=>{
        checkUnsavedChanges();
    },[unsavedChanges])

    function formatDateTime(timestamp)
    {
        let dateObj = new Date(Date.parse(timestamp));
        let hour = dateObj.getHours();
        let result = hour;
        let minutes = dateObj.getMinutes();
        let ext = '';
        if(hour > 12)
        {
            ext = 'PM';
            hour = (hour - 12);
            if(hour < 10)
            {
                result = "0" + hour;
            }else if(hour == 12)
            {
                hour = "00";
                ext = 'AM';
            }
        }
        else if(hour < 12)
        {
            result = ((hour < 10) ? "0" + hour : hour);
            ext = 'AM';
        }else if(hour == 12)
        {
            ext = 'PM';
        }
        if(minutes < 10){
            minutes = "0" + minutes; 
        }
        return `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()} ${result}:${minutes} ${ext}`;
    }

    return (
        <Accordion defaultActiveKey="1" className="mb-2">
            <Accordion.Item>
                <Accordion.Header style={{padding:"0",margin:"0",display:"flex"}} className="my-auto">
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p className="my-auto">{fieldValues['Rec_Service_Name']}</p>
                    </div>
                </Accordion.Header>
                    <Accordion.Body>
                        <div style={{display:"flex",justifyContent:"right",marginBottom:"10px"}}>
                            <Button variant='success' size='sm' style={{marginRight:"10px",display:saveDisplay}} onClick={updateComponent}>Save</Button>
                            <Button variant='secondary' size='sm' onClick={deleteComponent}>Delete</Button>
                        </div>
                        <FormInput id='Rec_Service_Name' title='Name' value={fieldValues['Rec_Service_Name']} marginRight='10px' initial={props.title} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='Standard_Notes' title='Standard Notes' value={fieldValues['Standard_Notes']} initial={props.standardNotes} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='Service_Notes' title='Service Notes' value={fieldValues['Service_Notes']} initial={props.serviceNotes} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='Man_Disclaimer' title='Manual Disclaimer' value={fieldValues['Man_Disclaimer']} initial={props.manualDisclaimer} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='AccessDisclaimer' title='Access Disclaimer' value={fieldValues['AccessDisclaimer']} initial={props.accessDisclaimer} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        {
                            props.namedElementsList.length > 0 &&
                            <BadgeList list={props.namedElementsList} title='Component Elements' bg='secondary'/>
                        }
                        <DropdownMultiselect
                            selected={fieldValues['psls']}
                            options={props.pslOptions}
                            name={`productsServicesMulti${props.id}`}
                            handleOnChange={(selected)=>{compareMultiSelectValue(selected);handleMutliselectChange(selected)}}
                            placeholder="Select related product or service"
                            className=""
                            buttonClass="btn-light"
                        />

                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",fontWeight:"lighter"}}>
                            {
                                props.createdAt && 
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Created: ${formatDateTime(props.createdAt)}`}</h6>
                            }
                            {
                                props.updatedAt &&
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Updated: ${formatDateTime(props.updatedAt)}`}</h6>
                            }
                        </div>
                    </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default ITRCard;