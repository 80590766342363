import React, {useEffect,useState} from "react";
import { InputGroup, FormControl, Modal, Button, Form, Spinner} from 'react-bootstrap';

class VersionModal extends React.Component
{
    constructor()
    {
        super();
        this.style = {
            h5: {
                marginBottom: "2px",
                marginTop: "7px"
            }
        };
        //create refs to modal fields
        this.versionName = React.createRef();
        this.description = React.createRef();
        this.active = React.createRef();
        this.source = React.createRef();
        this.createVersion = this.createVersion.bind(this);
        this.onCreateVersion = this.onCreateVersion.bind(this);
        this.state = {createCall:false};
    }

    async onCreateVersion(){
        this.setState({createCall:true});
        console.log(this.createCall);
        this.createVersion();
    }

    async createVersion()
    {
        let versionInfo = {
            name: this.versionName.current.value,
            description: this.description.current.value,
            active: this.active.current.checked,
            source: this.source.current.options[this.source.current.selectedIndex].text || "",  //https://stackoverflow.com/questions/14976495/get-selected-option-text-with-javascript
            sourceID: this.source.current.value
        }
        console.log(JSON.stringify(versionInfo));
        let fetchResults = await fetch("/version",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify(versionInfo)
        });
        //turn results into json
        let results = await fetchResults.json();
        //validate results
        if(!results.error)
        {
            //refresh versions page to show newly created version
            this.props.resetVersions();
        }
        this.props.onHide();
        this.setState({createCall:false});
    }


    render() {
    return <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Version
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={this.style.h5}>Name</h5>
        <InputGroup>
            <FormControl ref={this.versionName} aria-describedby="component-name" />
        </InputGroup>
        <h5 style={this.style.h5}>Description</h5>
        <InputGroup>
            <FormControl ref={this.description} aria-describedby="component-name" />
        </InputGroup>
        <h5 style={this.style.h5}>Active</h5>
        <Form.Check ref={this.active} id="componentName" aria-describedby="component-name" />
        <h5 style={this.style.h5}>Source</h5>
        <Form.Select ref={this.source} aria-label="Default select example">
        <option value="">None</option>
        {this.props.versionList}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.props.onHide} variant='secondary'>Cancel</Button>
        <Button onClick={async ()=>{await this.onCreateVersion()}}>
            {
                this.state.createCall ? <div><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/><span className="visually-hidden">Loading...</span></div>
                : "Create"
            }
        </Button>
      </Modal.Footer>
    </Modal>
    }
}

export default VersionModal;