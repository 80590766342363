import React from "react";
import {Badge,InputGroup} from 'react-bootstrap';

function BadgeList(props)
{
    let badgeArray = [];

    //loop through array in props
    for(let a = 0;a < props.list.length;a++)
    {
        badgeArray.push(<Badge key={a} className="me-2 my-auto py-2" bg={props.bg}>{props.list[a]}</Badge>)
    }


    return (
        <div style={{display:"flex",marginBottom:"10px"}}>
            <InputGroup.Text className="me-2 my-auto">{props.title}</InputGroup.Text>
            {badgeArray}
        </div>
    )
}

export default BadgeList;