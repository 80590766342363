import React, { useState } from "react";
import { InputGroup, FormControl, Modal, Button} from 'react-bootstrap';

function ITRModal(props)
{
  async function createComponent()
  {
    let fetchResults = await fetch("/itr",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify(fieldValues)
        });
        //turn results into json
        let results = await fetchResults.json();
        //validate results
        if(!results.error)
        {
            //refresh versions page to show newly created version
            props.resetView(['itr','psl']);
        }
        props.onHide();
  }

  const [fieldValues,setFieldValues] = useState({
    Rec_Service_Name:"",
    Standard_Notes: "",
    Service_Notes: "",
    Man_Disclaimer: "",
    AccessDisclaimer: "",
    version: props.version
  })

  function handleChange(event)
  {
    let newFields = {...fieldValues};
    newFields[event.target.id] = event.target.value;
    setFieldValues(newFields);
  }

  return (
  <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Create Recommendation
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InputGroup className="mb-2">
          <InputGroup.Text>Name</InputGroup.Text>
          <FormControl id="Rec_Service_Name" value={fieldValues["Rec_Service_Name"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Standard Notes</InputGroup.Text>
          <FormControl id="Standard_Notes" value={fieldValues["Standard_Notes"]} onChange={(event)=>handleChange(event)}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Service Notes</InputGroup.Text>
          <FormControl id="Service_Notes" value={fieldValues["Service_Notes"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Manual Disclaimer</InputGroup.Text>
          <FormControl id="Man_Disclaimer" value={fieldValues["Man_Disclaimer"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
      <InputGroup>
          <InputGroup.Text>Access Disclaimer</InputGroup.Text>
          <FormControl id="AccessDisclaimer" value={fieldValues["AccessDisclaimer"]} onChange={(event)=>handleChange(event)} />
      </InputGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} variant='secondary'>Cancel</Button>
      <Button onClick={createComponent}>Create</Button>
    </Modal.Footer>
  </Modal>
  );
}

export default ITRModal;