import React, { useEffect, useState, useRef } from 'react';
import CheckAuth from '../components/CheckAuth';
import Navbar from '../components/Navbar';
import ElementCard from '../components/elements/ElementCard';
import ElementModal from '../components/elements/ElementModal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useParams } from 'react-router-dom';
import {Alert,InputGroup, FormControl} from 'react-bootstrap';

function ViewAppComponent(props) 
{
    const [elements,setElements] = useState([]);
    //setup state to hold product service options
    const [itrOptions,setITROptions] = useState([]);
    //dragging variables setup
    const evalAuth = async() => {
        let auth = await CheckAuth();
        if(auth)
        {
            await returnITROptions();
        }else
        {
            window.location = "/login";
        }
    }
    async function returnElements()
    {
        let returnResults = await fetch(`/element/${component}`,{
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json results from call
        let results = await returnResults.json();
        //check results
        if(!results.error)
        {
            results.sort(function(a,b){
                return parseFloat(a.componentSort) - parseFloat(b.componentSort);
            })
            //create array of references
            let newReferences = [];
            //loop through result
            let elementsList = results.map((item,index)=>{
                //format each element in the array as a component
                const ref = React.createRef();
                newReferences.push(ref);
                //compare itrs vs available options
                let availableITRS = item.itrs.filter(function(itrItem){
                    return itrOptions.filter(e => e.key === itrItem).length > 0;
                });
                return <ElementCard ref={ref} id={index} key={`${item.fieldDisplayName}-${index}`} item={item} resetElements={returnElements} component={component} itrOptions={itrOptions} relatedITRS={availableITRS || []}/>;
            });
            //order = elementsList;
            setElements(elementsList);
            setElementCards(newReferences);
        }else
        {
            console.log("!" + results.error);
        }
    }

    async function returnComponent()
    {
        let returnResults = await fetch(`/component/single/${component}`,{
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json results from call
        let results = await returnResults.json();
        //check results
        if(results.error === undefined)
        {
            //setComponentObj
            setComponentObj(results);
        }
    }

    //get recommendation options from server
    const returnITROptions = async() => {
        let fetchResults = await fetch(`/itr/${version}`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        });
    
        let results = await fetchResults.json();
        //check results
        if(results.error === undefined)
        {
            //loop through result
            let itrOptionsArray = results.map((item,index)=>{
                return {key:item._id,label:item.Rec_Service_Name}
            });
            setITROptions(itrOptionsArray);
        }
    }

    //get version id from params
    const {version} = useParams();

    useEffect(()=>{
        evalAuth();
    },[])

    //use hook to update elements after itr options have been updated
    useEffect(async ()=>{
        if(itrOptions.length>0)
        {
            await returnComponent();
            await returnElements();
        }
    },[itrOptions])

    const [componentObj,setComponentObj] = useState({
        name: "",
        componentPrefix: ""
    })
    const {component} = useParams();

    //setup state for modal
    const [modalDisplay, setModalDisplay] = useState(false);

    //state handler for save all button
    const [show, setShow] = useState(true);

    //setup state for expand/collapse button
    const [expandCollapse,setExpandCollapse] = useState("Expand");
    //setup state for collapse references
    const [elementCards,setElementCards] = useState([]);
    function handleExpandCollapse()
    {
        function updateAccordions(state)
        {
            let newElements = [...elementCards];
            for(let a = 0;a < newElements.length;a++)
            {
                if(state === "Collapse")
                {
                    newElements[a].current.childNodes[0].childNodes[0].className = "accordion-button";
                    newElements[a].current.childNodes[0].childNodes[0].ariaExpanded = true;
                    newElements[a].current.childNodes[1].className = "accordion-collapse collapse show";
                }else
                {
                    newElements[a].current.childNodes[0].childNodes[0].className = "accordion-button collapsed";
                    newElements[a].current.childNodes[1].className = "accordion-collapse collapse";
                }
            }
        }
        if(expandCollapse === "Expand")
        {
            setExpandCollapse("Collapse");
            updateAccordions("Collapse");
        }else
        {
            setExpandCollapse("Expand")
            updateAccordions("Expand");
        }
    }

    const handleClose = (showModal) => setShow(showModal);

    return (
        <React.Fragment>
            <ElementModal show={modalDisplay} onHide={()=>setModalDisplay(false)} component={component} componentPrefix={componentObj.componentPrefix} resetElements={returnElements}/>
            <Navbar secondaryName={"Backend"}/>
                {/* Main Container */}
                <div className="container-fluid pt-3">
                {
                        // !modalDisplay &&
                        false &&
                        <button className="btn btn-success" style={{position: "fixed",backgroundColor:"green",zIndex:"4",bottom:"15px"}}>Save All
                        </button>
                    }
                    {/* Section Divider */}
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div style={{display:"flex"}}>
                                <h2 className="my-auto sectionTitle">{componentObj.name}</h2>
                                <button type="button" className="btn btn-primary btn-md my-auto ms-3" onClick={() => setModalDisplay(true)}>+ New</button>
                            </div>
                            <button className="btn btn-secondary" onClick={handleExpandCollapse}>{expandCollapse}</button>
                        </div>
                    {/* Card Section */}
                    <div className="my-3">
                        {
                            elements.length > 0 ? elements : <Alert key='1' variant='info'>No elements to show</Alert>
                        }
                        {
                            show &&
                            <div style={{height:"50px"}}></div>
                        }
                    </div>

                </div>

        </React.Fragment>
    );
  };

export default ViewAppComponent;