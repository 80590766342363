import React, { useState } from "react";
import { InputGroup, FormControl, Modal, Button, Form} from 'react-bootstrap';

function FilterModal(props)
{
  async function createDocument()
  {
    console.log(JSON.stringify(fieldValues));
    let fetchResults = await fetch("/filter",{
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(fieldValues)
    });
    //turn results into json
    let results = await fetchResults.json();
    console.log(results);
    //validate results
    if(!results.error)
    {
        //refresh versions page to show newly created version
        props.resetView(['filter']);
    }
    props.onHide();
  }

  const [fieldValues,setFieldValues] = useState({
    name:"",
    initial: false,
    version: props.version
  })

  function handleChange(event, fieldID)
  {
    let newFields = {...fieldValues};
    let value = event.target.value;
    if(fieldID === "initial"){
        value = event.target.checked;
    }
    newFields[fieldID] = value;
    setFieldValues(newFields);
  }

  return (
  <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Create Filter
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InputGroup className="mb-2">
          <InputGroup.Text>Name</InputGroup.Text>
          <FormControl id="name" value={fieldValues["name"]} onChange={(event)=>handleChange(event,"name")}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Initial</InputGroup.Text>
          <Form.Check className="ms-2 my-auto" id="initial" value={fieldValues["initial"]} onChange={(event)=>handleChange(event,"initial")}/>
      </InputGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} variant='secondary'>Cancel</Button>
      <Button onClick={createDocument}>Create</Button>
    </Modal.Footer>
  </Modal>
  );
}

export default FilterModal;