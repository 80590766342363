import React from 'react';
import { InputGroup, FormControl} from 'react-bootstrap';
import {Button,Accordion,Form} from 'react-bootstrap';
import FormInput from "../FormInput";
//import images
import upArrow from "../../images/viewcomponent/moveup.png";
import downArrow from "../../images/viewcomponent/movedown.png";
import CustomButton from '../CustomButton';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { isMobile } from 'react-device-detect';
import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ElementCard = React.forwardRef((props,ref) =>
//function ElementCard(props)
{
    console.log(props.itrOptions);
    async function updateComponent()
    {
        console.log(JSON.stringify({...fieldValues,component: props.component}));
        let updateResults = await fetch(`/element/${props.item._id}`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...fieldValues,component: props.component})
        });
        //get json from results
        let results = await updateResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetElements();
        }
    }

    async function deleteComponent()
    {
        let deleteResults = await fetch(`/element/${props.item._id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json from results
        let results = await deleteResults.json();
        //check results
        if(!results.error)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            await props.resetElements();
        }else
        {
            console.log("!" + results.error);
        }
    }

    async function updateElementOrder(direction)
    {
        let updateOrderResults = await fetch(`/element/order`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: props.item._id,
                direction: direction,
                component: props.component
            })
        });
        let updateOrder = await updateOrderResults.json();
        if(!updateOrder.error){
            setUnsavedChanges([]);
            setSaveDisplay("none");
            await props.resetElements();
        }
    }

    async function onMoveElementUp()
    {
        await updateElementOrder("up");
    }

    async function onMoveElementDown()
    {
        await updateElementOrder("down");
    }

    const style = {
        itemTitle:{
            textDecoration: 'none',
            color: 'inherit'
        },
        displayBetween:{
            display: 'flex',
            justifyContent: 'space-between'
        }
    }

    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

    //setup individual states for rich text fields
    const [inspectionGuidelinesRT, setInspectionGuidelinesRT] = useState(props.item.Inspection_Guidelines || "");
    const [addInfoRT, setAddInfoRT] = useState(props.item.Add_Info || "");
    const [proTipsRT, setproTipsRT] = useState(props.item.Pro_Tips || "");

    useEffect(()=>{
        compareCurrentValueWithInitial(inspectionGuidelinesRT,"Inspection_Guidelines",props.item.Inspection_Guidelines);
        handleChange(inspectionGuidelinesRT,"Inspection_Guidelines");
    },[inspectionGuidelinesRT])

    useEffect(()=>{
        compareCurrentValueWithInitial(addInfoRT,"Add_Info",props.item.Add_Info);
        handleChange(addInfoRT,"Add_Info");
    },[addInfoRT])

    useEffect(()=>{
        compareCurrentValueWithInitial(proTipsRT,"Pro_Tips",props.item.Pro_Tips);
        handleChange(proTipsRT,"Pro_Tips");
    },[proTipsRT])

    const [fieldValues,setFieldValues] = useState({
        fieldDisplayName: props.item.fieldDisplayName || "",
        prefabType: props.item.prefabType || "",
        prefabValues: props.item.prefabValues || "",
        sectionType: props.item.sectionType || "",
        sectionTypeOther:props.item.sectionType || "",
        ignoreBool: props.item.ignoreBool,
        warning: props.item.warning || "",
        failedItemDescription: props.item.failedItemDescription || "",
        dependency: props.item.dependency || "",
        relationalParent: props.item.relationalParent,
        relationalChild: props.item.relationalChild || "",
        unit: props.item.unit || "",
        TBDModifier: props.item.TBDModifier || "",
        Inspection_Guidelines: props.item.Inspection_Guidelines || "",
        Add_Info: props.item.Add_Info || "",
        Pro_Tips: props.item.Pro_Tips || "",
        Custom_Formula: props.item.Custom_Formula || "",
        SQL_Field_Name: props.item.SQL_Field_Name || "",
        itrs: props.relatedITRS || []
    });

    const sectionTypeOptions = ["Inspection Items","Measurements","Perspective Images","MeasurementUnit","Specifications","Other"]

    function handleChange(event, fieldID)
    {
        let newFields = {...fieldValues};
        let value = "";
        if(event.target)
        {
            value = event.target.value;
        }else
        {
            value = event;
        }
        console.log(fieldID);
        if(fieldID === "ignoreBool" || fieldID === "relationalParent"){
            value = event.target.checked;
        }
        newFields[fieldID] = value;
        setFieldValues(newFields);
    }

    function handleMutliselectChange(selected)
    {
        let newFields = {...fieldValues};
        newFields["itrs"] = selected;
        setFieldValues(newFields); 
    }

    //get element height
    let elHeight = document.getElementById(`componentItem${props.id}`);
    

    const [saveDisplay,setSaveDisplay] = useState("none");

    const [unsavedChanges,setUnsavedChanges] = useState([]);

    function compareCurrentValueWithInitial(event,customID,customInitial)
    {
        let initial;
        let value;
        let id;
        if(event.target)
        {
            initial = event.target.getAttribute("initial");
            value = event.target.value;
            id = event.target.getAttribute("id");
        }else
        {
            initial = customInitial;
            value = event;
            id = customID;
        }
        if(value !== initial && saveDisplay === "none")
        {
            setSaveDisplay("");
            if(event.target)
            {
                const updatedUnsavedChanges = [...unsavedChanges,event.target];
                setUnsavedChanges(updatedUnsavedChanges);
            }else
            {
                const updatedUnsavedChanges = [...unsavedChanges,{
                    getAttribute: function(){
                        return customID;
                    }
                }];
                setUnsavedChanges(updatedUnsavedChanges);
            }


        }else if(value == initial)
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(value !== initial)
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                if(event.target)
                {
                    const updatedUnsavedChanges = [...unsavedChanges,event.target];
                    setUnsavedChanges(updatedUnsavedChanges);
                }else
                {
                    const updatedUnsavedChanges = [...unsavedChanges,{
                        getAttribute: function(){
                            return customID;
                        }
                    }];
                    setUnsavedChanges(updatedUnsavedChanges);
                }
            }
        }
    }

    function compareMultiSelectValue(selected)
    {
        //compare two arrays using json stringify
        console.log(JSON.stringify(selected) + " vs " + JSON.stringify(props.relatedITRS));
        if(JSON.stringify(selected) != JSON.stringify(props.relatedITRS) && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,{
                getAttribute: function(id)
                {
                    return "itrs";
                }
            }];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(JSON.stringify(selected) == JSON.stringify(props.relatedITRS))
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === "itrs")
                {
                    console.log("remove multiselect");
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(JSON.stringify(selected) != JSON.stringify(props.relatedITRS))
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === "itrs")
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,{
                    getAttribute: function(id)
                    {
                        return "itrs";
                    }
                }];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function checkUnsavedChanges()
    {
        if(unsavedChanges.length === 0)
        {
            setSaveDisplay("none");
        }
    }

    function onRichTextChange(value,id,initial){
        compareCurrentValueWithInitial(value,id,initial);handleChange(value,id)
    }

    useEffect(()=>{
        checkUnsavedChanges();
    },[unsavedChanges])

    function formatDateTime(timestamp)
    {
        let dateObj = new Date(Date.parse(timestamp));
        let hour = dateObj.getHours();
        let result = hour;
        let minutes = dateObj.getMinutes();
        let ext = '';
        if(hour > 12)
        {
            ext = 'PM';
            hour = (hour - 12);
            if(hour < 10)
            {
                result = "0" + hour;
            }else if(hour == 12)
            {
                hour = "00";
                ext = 'AM';
            }
        }
        else if(hour < 12)
        {
            result = ((hour < 10) ? "0" + hour : hour);
            ext = 'AM';
        }else if(hour == 12)
        {
            ext = 'PM';
        }
        if(minutes < 10){
            minutes = "0" + minutes; 
        }
        return `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()} ${result}:${minutes} ${ext}`;
    }

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]

    return (
        /*<motion.div style={{
            zIndex: isdragged ? 3 : 1,
            position: "relative",
            height: elHeight
          }}
          dragConstraints={{
            top: 0,
            bottom: 0
          }}
          dragElastic={1}
          layout
          ref={itemRef}
          onDragStart={() => setIsDragged(true)}
          onDragEnd={() => setIsDragged(false)}
          animate={{
            //scale: isdragged ? 1.05 : 1
          }}
          onViewportBoxUpdate={(_, delta) => {
           isdragged && props.updateOrder(props.id, delta.y.translate);
          }}
          drag="y">*/
        <Accordion id={`componentItem${props.id}`} className="mb-2">
            <Accordion.Item eventKey="0" ref={ref}>
            <Accordion.Header>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                    <p className="my-auto">{props.item.fieldDisplayName}</p>
                </div>
            </Accordion.Header>
                <Accordion.Body className="test">
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"15px"}}>
                        <div style={{}}>
                            <CustomButton width="33px" height="33px" image={upArrow} onClick={onMoveElementUp} marginRight="10px"/>
                            <CustomButton width="33px" height="33px" image={downArrow} onClick={onMoveElementDown}/>
                        </div>
                        <div>
                            <Button variant="success" className="me-3" size="sm" style={{display:saveDisplay}} onClick={updateComponent}>Save</Button>
                            <Button variant="secondary" size="sm" onClick={deleteComponent}>Delete</Button>
                        </div>
                    </div>
                    <div style={{display:"flex",flexDirection:direction}}>
                        <FormInput id='fieldDisplayName' title='Display Name' value={fieldValues['fieldDisplayName']} marginRight='10px' initial={props.item.fieldDisplayName} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>Prefab Type</InputGroup.Text>
                            <Form.Select id='prefabType' value={fieldValues['prefabType']} aria-label="Prefab Type" onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,"prefabType")}}>
                                <option value="">Select a prefab type</option>
                                <option value="Inspection Group">Inspection Group</option>
                                <option value="Select All">Select All</option>
                                <option value="Measurement">Measurement</option>
                                <option value="MeasurementUnit">MeasurementUnit</option>
                                <option value="Short Text">Short Text</option>
                                <option value="Long Text">Long Text</option>
                                <option value="Radio Select">Radio Select</option>
                                <option value="Image Capture">Image Capture</option>
                                <option value="Artwork">Artwork</option>
                                <option value="DiagramName">DiagramName</option>
                            </Form.Select>
                        </InputGroup>
                        {/* <FormInput id='prefabType' title='Prefab Type' value={fieldValues['prefabType']} initial={props.item.prefabType} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/> */}
                    </div>
                    <div style={{display:"flex",flexDirection:direction}}>
                        <FormInput id='prefabValues' title='Prefab Values' value={fieldValues['prefabValues']} marginRight='10px' initial={props.item.prefabValues} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        {/* <FormInput id='sectionType' title='Section Type' value={fieldValues['sectionType']} initial={props.item.sectionType} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/> */}
                        <InputGroup className="mb-2">
                            <InputGroup.Text>Section Type</InputGroup.Text>
                            <Form.Select id='sectionType' value={fieldValues['sectionType']} aria-label="Section Type" onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,"sectionType")}}>
                                <option value="">Select a section type</option>
                                {sectionTypeOptions.map(function(item,index){
                                    return <option key={`${item}-${index}`} value={item}>{item}</option>;
                                })}
                            </Form.Select>
                            {
                                fieldValues['sectionType'] === "Other" || !sectionTypeOptions.includes(fieldValues['sectionType']) ? <FormControl value={fieldValues['sectionType']} className="ms-3" onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,'sectionType')}}/> : ""
                            }
                        </InputGroup>
                    </div>
                    <div style={{display:"flex",flexDirection:direction}}>
                        {/* <FormInput id='ignoreBool' title='Ignore' value={fieldValues['ignoreBool']} marginRight='10px' initial={props.item.ignoreBool.toString()} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/> */}
                        <InputGroup className="mb-2">
                                <InputGroup.Text style={{borderTopRightRadius: "0.25rem",borderBottomRightRadius: "0.25rem"}}>Ignore</InputGroup.Text>
                                <Form.Check className="ms-2 my-auto" id="ignoreBool" checked={fieldValues["ignoreBool"]} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,"ignoreBool")}}/>
                        </InputGroup>
                        <FormInput id='warning' title='Warning' value={fieldValues['warning']} initial={props.item.warning} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </div>
                    <div style={{display:"flex",flexDirection:direction}}>
                        {/* <FormInput id='relationalParent' title='Relational Parent' value={fieldValues['relationalParent']} marginRight='10px' initial={props.item.relationalParent.toString()} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/> */}
                        <InputGroup className="mb-2">
                                <InputGroup.Text style={{borderTopRightRadius: "0.25rem",borderBottomRightRadius: "0.25rem"}}>Relational Parent</InputGroup.Text>
                                <Form.Check className="ms-2 my-auto" id="relationalParent" checked={fieldValues["relationalParent"]} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,"relationalParent")}}/>
                        </InputGroup>
                        <FormInput id='relationalChild' title='Relational Child' value={fieldValues['relationalChild']} initial={props.item.relationalChild} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </div>
                    <div style={{display:"flex",flexDirection:direction}}>
                        <FormInput id='failedItemDescription' title='Failed Item Description' value={fieldValues['failedItemDescription']} marginRight='10px' initial={props.item.failedItemDescription} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='dependency' title='Dependency' value={fieldValues['dependency']} initial={props.item.dependency} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </div>
                    <div style={{display:"flex",flexDirection:direction}}>
                        <FormInput id='unit' title='Unit' value={fieldValues['unit']} marginRight='10px' initial={props.item.unit} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='TBDModifier' title='TBD Modifier' value={fieldValues['TBDModifier']} initial={props.item.TBDModifier} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </div>
                    <InputGroup.Text style={{borderBottomRightRadius: "0",borderBottomLeftRadius: "0"}}>Inspection Guidelines</InputGroup.Text>
                    <ReactQuill modules={modules} formats={formats} id="Inspection_Guidelines" style={{marginBottom: "10px"}} theme="snow" value={inspectionGuidelinesRT} initial={props.item.Inspection_Guidelines} onChange={setInspectionGuidelinesRT}/>
                    {/* <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Text>Inspection Guidelines</InputGroup.Text>
                        <FormControl style={{height:"115px"}} id='Inspection_Guidelines' as="textarea" aria-label="Inspection Guidelines" text={fieldValues['Inspection_Guidelines']} defaultValue={props.item.Inspection_Guidelines} initial={props.item.Inspection_Guidelines} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </InputGroup> */}

                    <InputGroup.Text style={{borderBottomRightRadius: "0",borderBottomLeftRadius: "0"}}>Additional Information</InputGroup.Text>
                    <ReactQuill modules={modules} formats={formats} id="Add_Info" style={{marginBottom: "10px"}} theme="snow" value={addInfoRT} initial={props.item.Add_Info} onChange={setAddInfoRT}/>
                    {/* <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Text>Additional Information</InputGroup.Text>
                        <FormControl style={{height:"115px"}} id='Add_Info' as="textarea" aria-label="Additional Information" text={fieldValues['Add_Info']} initial={props.item.Add_Info} defaultValue={props.item.Add_Info} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </InputGroup> */}

                    <InputGroup.Text style={{borderBottomRightRadius: "0",borderBottomLeftRadius: "0"}}>Pro Tips</InputGroup.Text>
                    <ReactQuill modules={modules} formats={formats} id="Pro_Tips" style={{marginBottom: "10px"}} theme="snow" value={proTipsRT} initial={props.item.Pro_Tips} onChange={setproTipsRT}/>
                    {/* <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Text>Pro Tips</InputGroup.Text>
                        <FormControl style={{height:"115px"}} id='Pro_Tips' as="textarea" aria-label="Pro Tips" text={fieldValues['Pro_Tips']} defaultValue={props.item.Pro_Tips} initial={props.item.Pro_Tips} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                    </InputGroup> */}
                    <div style={{display:"flex",flexDirection:direction}}>
                        <FormInput id='SQL_Field_Name' title='Field Key Name' value={fieldValues['SQL_Field_Name']} marginRight='10px' initial={props.item.SQL_Field_Name} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        <FormInput id='Custom_Formula' title='Custom Formula' value={fieldValues['Custom_Formula']} initial={props.item.Custom_Formula} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                        {/* <FormInput id='Pro_Tips' title='Pro Tips' value={fieldValues['Pro_Tips']} marginRight='10px' initial={props.item.Pro_Tips} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/> */}
                    </div>
                    <DropdownMultiselect
                            selected={fieldValues['itrs']}
                            options={props.itrOptions}
                            name={`productsServicesMulti${props.id}`}
                            handleOnChange={(selected)=>{compareMultiSelectValue(selected);handleMutliselectChange(selected)}}
                            placeholder="Select related recommendation"
                            className=""
                            buttonClass="btn-light"
                        />
                    <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",fontWeight:"lighter"}}>
                        {
                            props.createdAt && 
                            <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Created: ${formatDateTime(props.item.createdAt)}`}</h6>
                        }
                        {
                            props.updatedAt &&
                            <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Updated: ${formatDateTime(props.item.updatedAt)}`}</h6>
                        }
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        // </motion.div>
    );
});

export default ElementCard;