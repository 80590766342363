import React, { useState } from "react";
import { InputGroup, FormControl, Modal, Button, Form} from 'react-bootstrap';
import FormInput from "../FormInput";
import { isMobile } from "react-device-detect";

function EmployeeModal(props)
{
    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

  async function createDocument()
  {
    //create property for full name
    fieldValues.FullName = `${fieldValues.FirstName} ${fieldValues.LastName}`;
    // console.log(JSON.stringify(fieldValues));
    let fetchResults = await fetch("/employee",{
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(fieldValues)
    });
    //turn results into json
    let results = await fetchResults.json();
    console.log(results);
    //validate results
    if(!results.error)
    {
        //refresh versions page to show newly created version
        props.resetView(['employee']);
    }
    props.onHide();
  }

  const [fieldValues,setFieldValues] = useState({
    "Permission": "",
    "ActiveStatus": "",
    "TechClass": "",
    "PIN": "",
    "Position":"", 
    "Branch_ID": "",
    "FirstName": "",
    "LastName": "",
    "BitrixID": "",
    "Headshot":"",
    version: props.version
  })

  function handleChange(event, fieldID)
  {
    let newFields = {...fieldValues};
    let value = event.target.value;
    console.log(value);
    if(fieldID === "initial"){
        value = event.target.checked;
    }
    newFields[fieldID] = value;
    setFieldValues(newFields);
  }

  return (
  <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Create Employee
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='FirstName' title='First Name' value={fieldValues['FirstName']} marginRight='10px' onChange={(event)=>handleChange(event,"FirstName")}/>
            <FormInput id='LastName' title='Last Name' value={fieldValues['LastName']} marginRight='10px' onChange={(event)=>handleChange(event,"LastName")}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='PIN' title='PIN' value={fieldValues['PIN']} marginRight='10px' onChange={(event)=>handleChange(event,"PIN")}/>
            <FormInput id='Permission' title='Permission' value={fieldValues['Permission']} marginRight='10px' onChange={(event)=>handleChange(event,"Permission")}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='Position' title='Position' value={fieldValues['Position']} marginRight='10px' onChange={(event)=>handleChange(event,"Position")}/>
            <FormInput id='TechClass' title='Tech Class' value={fieldValues['TechClass']} onChange={(event)=>handleChange(event,"TechClass")}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='Branch_ID' title='Branch ID' value={fieldValues['Branch_ID']} marginRight='10px' onChange={(event)=>handleChange(event,"Branch_ID")}/>
            <FormInput id='BitrixID' title='Bitrix ID' value={fieldValues['BitrixID']} onChange={(event)=>handleChange(event,"BitrixID")}/>
        </div>
        <div style={{display:"flex",flexDirection:direction}}>
            <FormInput id='Headshot' title='Headshot URL' value={fieldValues['Headshot']} onChange={(event)=>handleChange(event,"Headshot")}/>
        </div>



      {/* <InputGroup className="mb-2">
          <InputGroup.Text>Name</InputGroup.Text>
          <FormControl id="name" value={fieldValues["name"]} onChange={(event)=>handleChange(event,"name")}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Initial</InputGroup.Text>
          <Form.Check className="ms-2 my-auto" id="initial" value={fieldValues["initial"]} onChange={(event)=>handleChange(event,"initial")}/>
      </InputGroup> */}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} variant='secondary'>Cancel</Button>
      <Button onClick={createDocument}>Create</Button>
    </Modal.Footer>
  </Modal>
  );
}

export default EmployeeModal;