import React, { useState } from  'react';

import {
    BrowserRouter as Router,
    Route,
    Switch
  } from 'react-router-dom'

import Login from './pages/Login';
import Home from './pages/Home';
import AppVersions from './pages/AppVersions';
import ViewAppComponent from './pages/ViewAppComponent';
import AppComponents from './pages/AppComponents';

const styles = {
    body:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
}
  

function App()
{
  return (  
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login/>
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/versions">
            <AppVersions/>
          </Route>
          <Route exact path="/components/:version">
              <AppComponents/>
          </Route>
          <Route exact path="/viewcomponent/:version/:component">
              <ViewAppComponent/>
          </Route>
        </Switch>
      </Router>
    );
}

export default App;