import React, { useState } from "react";
import { InputGroup, FormControl, Modal, Button} from 'react-bootstrap';

function AppComponentModal(props)
{
  async function createComponent()
  {
    console.log(JSON.stringify(fieldValues));
    let fetchResults = await fetch("/component",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify(fieldValues)
        });
        //turn results into json
        let results = await fetchResults.json();
        //validate results
        if(!results.error)
        {
            //refresh versions page to show newly created version
            props.resetView(['component']);
        }
        props.onHide();
  }

  const [fieldValues,setFieldValues] = useState({
    name:"",
    description: "",
    componentPrefix: "",
    version: props.version
  })

  function handleChange(event, fieldID)
  {
    let newFields = {...fieldValues};
    newFields[fieldID] = event.target.value;
    setFieldValues(newFields);
  }

  return (
  <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Create Component
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InputGroup className="mb-2">
          <InputGroup.Text>Name</InputGroup.Text>
          <FormControl id="name" value={fieldValues["name"]} onChange={(event)=>handleChange(event,"name")}/>
      </InputGroup>
      <InputGroup className="mb-2">
          <InputGroup.Text>Description</InputGroup.Text>
          <FormControl id="description" value={fieldValues["description"]} onChange={(event)=>handleChange(event,"description")}/>
      </InputGroup>
      <InputGroup>
          <InputGroup.Text>Component Prefix</InputGroup.Text>
          <FormControl id="componentPrefix" value={fieldValues["componentPrefix"]} onChange={(event)=>handleChange(event,"componentPrefix")} />
      </InputGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} variant='secondary'>Cancel</Button>
      <Button onClick={createComponent}>Create</Button>
    </Modal.Footer>
  </Modal>
  );
}

export default AppComponentModal;