import React, { useEffect, useState } from 'react';
import {BrowserView, MobileView } from 'react-device-detect';
//import components
import Navbar from '../components/Navbar';
import CustomButton from '../components/CustomButton';
//import images
import chimneyTrackIcon from '../images/home/chimneytrackicon.png';
import banner from '../images/home/banner.png';
import eventData from '../images/home/eventdata.png';
import CheckAuth from '../components/CheckAuth';
import routeView from '../images/home/routeview.png';
import backend from '../images/home/backend.png';
//import bootstrap
import '../../node_modules/bootstrap/dist/css/bootstrap.css';

const Home = () => {
    const evalAuth = async() => {
        let auth = await CheckAuth();
        if(auth)
        {
           await callAPI();
        }else
        {
            window.location = "/login";
        }
    }

    const callAPI = async() => {
        let fetchResults = await fetch("/version",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            credentials: 'same-origin'
        });
    
        let results = await fetchResults.json();
    
        setHello(results.message);
    }

    const [hello,setHello] = useState("");  //use with {hello}

    useEffect(()=>{
        evalAuth();
    },[])

    const styles = {
        body: {
        },
        banner: {
            width:'100%',
            display: 'flex',
            justifyContent:'center',
            marginTop: '50px',
            paddingBottom: '50px'
        },
        buttonGroupBrowser: {
            display: 'flex',
            justifyContent:'space-between',
            alignItems: 'center',
            marginLeft: '5em',
            marginRight: '5em',
            marginTop: '45px',
            marginBottom: '45px'
        },
        buttonGroupMobile: {
            display: 'flex',
            justifyContent:'center',
            flexDirection:'column',
            alignItems: 'center',
            marginLeft: '5vw',
            marginRight: '5vw',
            marginTop: '45px',
            marginBottom: '45px'
        }
    }


    return (
        <React.Fragment>
            <BrowserView style={{height: '100%'}}>
                <Navbar/>
                <div style={styles.body}>
                    <div style={styles.banner}>
                        <img src={banner} style={{width:'580px',height:'90px'}}></img>
                    </div>
                    <div style={styles.buttonGroupBrowser}>
                        <CustomButton width='15vw' height='13vw' image={eventData} onClick={()=>{}}/>
                        <CustomButton width='15vw' height='13vw' image={routeView} onClick={()=>{}}/>
                        <CustomButton width='15vw' height='13vw' image={backend} onClick={()=>{window.location="/versions"}}/>
                    </div>
                </div>
            </BrowserView>
            <MobileView style={{height: '100%'}}>
            <Navbar/>
            <div style={styles.body}>
                    <div style={styles.banner}>
                        <img src={banner} style={{width:'80vw',height:'13vw'}}></img>
                    </div>
                    <div style={styles.buttonGroupMobile}>
                            <CustomButton width='25vw' height='21vw' image={eventData} onClick={()=>{}} marginBottom='40px'/>
                            <CustomButton width='25vw' height='21vw' image={routeView} onClick={()=>{}} marginTop='40px' marginBottom='40px'/>
                            <CustomButton width='25vw' height='21vw' image={backend} onClick={()=>{window.location="/versions"}} marginTop='40px'/>
                    </div>
                </div>
            </MobileView>
        </React.Fragment>
    );
  };

export default Home;