import React, {useState, useEffect} from "react";
import {Button, Accordion, Badge, Card, InputGroup, Form} from 'react-bootstrap';
import FormInput from "../FormInput";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { isMobile } from "react-device-detect";
//import style
import '../../styles/custom-form.css';
//import images
import upArrow from "../../images/viewcomponent/moveup.png";
import downArrow from "../../images/viewcomponent/movedown.png";
import CustomButton from '../CustomButton';

function FilterCard(props)
{
    async function updateDocument()
    {
        let updateResults = await fetch(`/filter/${props.item._id}`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...fieldValues,version:props.versionID})
        });
        //get json from results
        let results = await updateResults.json();
        console.log(results);
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['filter']);
        }
    }

    async function deleteDocument()
    {
        let deleteResults = await fetch(`/filter/${props.item._id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        //get json from results
        let results = await deleteResults.json();
        //check results
        if(results.error === undefined)
        {
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['filter']);
        }
    }

    async function updateOrder(direction)
    {
        let updateOrderResults = await fetch(`/filter/order`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: props.item._id,
                direction: direction,
                version:props.versionID
            })
        });
        let updateOrder = await updateOrderResults.json();
        if(!updateOrder.error){
            setUnsavedChanges([]);
            setSaveDisplay("none");
            props.resetView(['filter']);
        }
    }

    async function onMoveUp()
    {
        await updateOrder("up");
    }

    async function onMoveDown()
    {
        await updateOrder("down");
    }

    let direction = "row";
    if(isMobile)
    {
        direction = "column";
    }

    const [fieldValues,setFieldValues] = useState({
        "name": props.item.name || "",
        "initial": props.item.initial || false,
        "components":  props.relatedComponents || []
    });

    function handleChange(event, fieldID)
    {
        let newFields = {...fieldValues};
        let value = event.target.value;
        if(fieldID === "initial"){
            value = event.target.checked;
        }
        console.log(fieldID);
        newFields[fieldID] = value;
        setFieldValues(newFields);
    }

    function handleMutliselectChange(selected)
    {
        let newFields = {...fieldValues};
        newFields["components"] = selected;
        setFieldValues(newFields); 
    }

    const [saveDisplay,setSaveDisplay] = useState("none");

    const [unsavedChanges,setUnsavedChanges] = useState([]);

    function compareCurrentValueWithInitial(event)
    {
        let initial = event.target.getAttribute("initial");
        let value = event.target.value;
        let id = event.target.getAttribute("id");
        if(value !== initial && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,event.target];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(value == initial)
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(value !== initial)
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === id)
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,event.target];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function compareMultiSelectValue(selected)
    {
        //compare two arrays using json stringify
        console.log(JSON.stringify(selected) + " vs " + JSON.stringify(props.relatedComponents));
        if(JSON.stringify(selected) != JSON.stringify(props.relatedComponents) && saveDisplay === "none")
        {
            setSaveDisplay("");
            const updatedUnsavedChanges = [...unsavedChanges,{
                getAttribute: function(id)
                {
                    return "components";
                }
            }];
            setUnsavedChanges(updatedUnsavedChanges);
        }else if(JSON.stringify(selected) == JSON.stringify(props.relatedComponents))
        {
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === "components")
                {
                    console.log("remove multiselect");
                    //remove current selection from array
                    const updatedUnsavedChanges = [...unsavedChanges];
                    updatedUnsavedChanges.splice(a,1);
                    setUnsavedChanges(updatedUnsavedChanges);
                    break;
                }
            }
        }else if(JSON.stringify(selected) != JSON.stringify(props.relatedComponents))
        {
            let foundElement = false;
            //look for existing element in unsaved changes
            for(let a = 0;a < unsavedChanges.length;a++)
            {
                if(unsavedChanges[a].getAttribute("id") === "components")
                {
                    foundElement = true;
                    break;
                }
            }
            if(!foundElement)
            {
                const updatedUnsavedChanges = [...unsavedChanges,{
                    getAttribute: function(id)
                    {
                        return "components";
                    }
                }];
                setUnsavedChanges(updatedUnsavedChanges);
            }
        }
    }

    function checkUnsavedChanges()
    {
        if(unsavedChanges.length === 0)
        {
            setSaveDisplay("none");
        }
    }

    useEffect(()=>{
        checkUnsavedChanges();
    },[unsavedChanges])

    function formatDateTime(timestamp)
    {
        let dateObj = new Date(Date.parse(timestamp));
        let hour = dateObj.getHours();
        let result = hour;
        let minutes = dateObj.getMinutes();
        let ext = '';
        if(hour > 12)
        {
            ext = 'PM';
            hour = (hour - 12);
            if(hour < 10)
            {
                result = "0" + hour;
            }else if(hour == 12)
            {
                hour = "00";
                ext = 'AM';
            }
        }
        else if(hour < 12)
        {
            result = ((hour < 10) ? "0" + hour : hour);
            ext = 'AM';
        }else if(hour == 12)
        {
            ext = 'PM';
        }
        if(minutes < 10){
            minutes = "0" + minutes; 
        }
        return `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()} ${result}:${minutes} ${ext}`;
    }

    return (
        <Accordion defaultActiveKey="1" className="mb-2">
            <Accordion.Item>
                <Accordion.Header style={{padding:"0",margin:"0",display:"flex"}} className="my-auto">
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p className="my-auto">{fieldValues['name']}</p>
                    </div>
                </Accordion.Header>
                    <Accordion.Body>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"15px"}}>
                            <div>
                                <CustomButton width="33px" height="33px" image={upArrow} onClick={onMoveUp} marginRight="10px"/>
                                <CustomButton width="33px" height="33px" image={downArrow} onClick={onMoveDown}/>
                            </div>
                            <div>
                                <Button className="mb-auto align-top" variant='success' size='sm' style={{marginRight:"10px",display:saveDisplay}} onClick={updateDocument}>Save</Button>
                                <Button className="mb-auto align-top" variant='secondary' size='sm' onClick={deleteDocument}>Delete</Button>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:direction}}>
                            <FormInput id='name' title='Filter Name' value={fieldValues['name']} marginRight='10px' initial={props.item.name} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,id)}}/>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>Initial</InputGroup.Text>
                                <Form.Check className="ms-2 my-auto" id="initial" checked={fieldValues["initial"]} onChange={(event,id)=>{compareCurrentValueWithInitial(event);handleChange(event,"initial")}}/>
                            </InputGroup>
                        </div>
                        <DropdownMultiselect
                            selected={fieldValues['components']}
                            options={props.componentOptions}
                            name={`componentOptionsSelect${props.id}`}
                            handleOnChange={(selected)=>{compareMultiSelectValue(selected);handleMutliselectChange(selected)}}
                            placeholder="Select related components"
                            className=""
                            buttonClass="btn-light"
                        />
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",fontWeight:"lighter"}}>
                            {
                                props.createdAt && 
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Created: ${formatDateTime(props.createdAt)}`}</h6>
                            }
                            {
                                props.updatedAt &&
                                <h6 className="mb-0" style={{fontWeight:"normal"}}>{`Updated: ${formatDateTime(props.updatedAt)}`}</h6>
                            }
                        </div>
                    </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default FilterCard;