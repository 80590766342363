import React, {useState} from 'react';
import { InputGroup, FormControl} from 'react-bootstrap';

function FormInput({title,id,value,marginRight,readOnly,onChange,initial})
{
    return (
        <InputGroup className="mb-2" style={{marginRight:marginRight}}>
            <InputGroup.Text>{title}</InputGroup.Text>
            <FormControl value={value} id={id} aria-describedby={id} onChange={(event)=>{onChange(event,id)}} readOnly={readOnly} initial={initial} /*readOnly={displayNameActiveState} onDoubleClick={()=>{setDisplayNameActiveState(false)}} onBlur={()=>{setDisplayNameActiveState(true)}}*//>
        </InputGroup>
    );
}

export default FormInput;