async function CheckAuth() {
    let authResults = await fetch(`/user/auth`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    //get json results of auth
    let auth = await authResults.json();
    //if auth is not error
    if(!auth.error)
    {
      /*expected payload
      {
          permission: 'Admin',
          _id: '61771e2904c33dd064924f40',
          firstName: 'Lane',
          lastName: 'Pemberton',
          username: 'lanepemberton',
          password: '$2b$10$LkDLam42nljkAo8024I6IOPLXBts1W10pCrm37zF3PIuNsthuY1xW',
          createdAt: '2021-10-25T21:14:17.083Z',
          updatedAt: '2021-10-25T21:14:17.083Z',
      }*/
      return true
    }else
    {
      return false
    }
}

export default CheckAuth;