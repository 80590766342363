import React from 'react';
//import '../../node_modules/bootstrap/dist/css/bootstrap.css';

function CustomButton({image, onClick, width, height, marginTop = "", marginBottom = "", marginLeft="",marginRight=""})
{
    const style = {
        backgroundImage: `url("${image}")`,
        backgroundSize: '100% 100%',
        width: width,
        height: height,
        border: '0px',
        backgroundColor: "white",
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight
    }

    return (
        <button style={style} onClick={onClick}>
        </button>
    );
}

export default CustomButton;
